import React from "react";
import { PageProps } from "gatsby";

import SEO from "../components/SEO";
import { Cart } from "../components/Cart";

const DutchCartPage: React.FC<PageProps> = ({ location }) => (
  <>
    <SEO title="Winkelmand" lang="nl" pathName={location.pathname} />
    <Cart language="nl" />
  </>
);

export default DutchCartPage;
